import React from 'react'
import { navigate, Router } from '@reach/router'
import { Link } from 'gatsby'
import Login, { signIn } from '../components/Login'
import Layout from "../components/Layout"
import accountStyles from "../styles/pages/account.module.scss"
import { Container, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button';

const isAuthenticated = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('isAuthenticated') === 'true';
  } else {
    return false;
  }
};

class Logout extends React.Component {    
  constructor(props) {
    super(props);

    this.state = {user: false};
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    const token = await signIn.authClient.tokenManager.get('idToken');
    if (token) {
      this.setState({user: token.claims.name});
    } else {
      // Token has expired
      this.setState({user: false});
      localStorage.setItem('isAuthenticated', 'false');
    }
  }

  logout() {
    signIn.authClient.signOut().catch((error) => {
      console.error('Sign out error: ' + error)
    }).then(() => {
      localStorage.setItem('isAuthenticated', 'false');
      this.setState({user: false});
      navigate('/');
    });
  }
  
  render() {        
    if (!isAuthenticated()) {
      return (
        <Login/>
      );
    } else {
        return (
            <React.Fragment>
                <Button onClick={this.logout}>Logout</Button>
            </React.Fragment>
        )
    }
    
  }
}

export default Logout